var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ManagementLayout',{directives:[{name:"intersect",rawName:"v-intersect",value:({ handler: _vm.onIntersect }),expression:"{ handler: onIntersect }"}],attrs:{"show":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Personal Access Tokens")]},proxy:true},{key:"subtitle",fn:function(){return [_c('h4',{staticClass:"error--text"},[_c('v-icon',{staticClass:"error--text mr-1"},[_vm._v("error_outline")]),_vm._v("DEPRECATED")],1),_c('div',[_vm._v("Use the "),_c('router-link',{attrs:{"to":{ name: 'keys' }}},[_vm._v(" API Keys")]),_vm._v(" page going forward.")],1),_vm._v(" Existing tokens will continue to work, but new ones cannot be created. "),_c('br')]},proxy:true}])},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-text-field',{attrs:{"solo":"","dense":"","hide-details":"","single-line":"","placeholder":"Search for an API Token","prepend-inner-icon":"search","autocomplete":"new-password"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-card',{attrs:{"tile":""}},[_c('v-card-text',{staticClass:"pa-0"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"py-1 mr-2 flex"},[_c('v-text-field',{staticClass:"rounded-0 elevation-1",style:({
            'max-width': _vm.$vuetify.breakpoint.mdAndUp ? '360px' : null
          }),attrs:{"solo":"","dense":"","hide-details":"","single-line":"","placeholder":"Search for an API Token","prepend-inner-icon":"search","autocomplete":"new-password"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-2 rounded-0 truncate-table",attrs:{"fixed-header":"","headers":_vm.headers,"header-props":{ 'sort-icon': 'arrow_drop_up' },"search":_vm.search,"items":_vm.tokens,"loading":_vm.$apollo.queries.tokens.loading,"items-per-page":10,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right'
        },"no-results-text":"No tokens found. Try expanding your search?","no-data-text":"You do not have any personal access tokens yet."},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.created",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.last_used",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.expires_at",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.created",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.created ? _vm.formDate(item.created) : '')+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.created ? _vm.formatTime(item.created) : '')+" ")])])]}},{key:"item.last_used",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.last_used ? _vm.formDate(item.last_used) : '')+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.last_used ? _vm.formatTime(item.last_used) : '')+" ")])])]}},{key:"item.expires_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.expires_at ? _vm.formatTimeRelative(item.expires_at) : 'Never')+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","x-small":"","color":"error"},on:{"click":function($event){_vm.tokenToDelete = item
                  _vm.tokenToDeleteDialog = true}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_vm._v(" Revoke token ")])]}}])})],1)],1),(_vm.tokenToDelete)?_c('ConfirmDialog',{attrs:{"type":"error","dialog-props":{ 'max-width': '500' },"title":("Are you sure you want to revoke the token\n        " + (_vm.tokenToDelete.name) + "?"),"confirm-text":"Revoke"},on:{"confirm":function($event){return _vm.deleteToken(_vm.tokenToDelete)}},model:{value:(_vm.tokenToDeleteDialog),callback:function ($$v) {_vm.tokenToDeleteDialog=$$v},expression:"tokenToDeleteDialog"}},[_vm._v(" Once you delete this token, you will not be able to use it again to interact with the Prefect Cloud API. ")]):_vm._e(),_c('Alert',{attrs:{"type":_vm.alertType,"message":_vm.alertMessage,"offset-x":_vm.$vuetify.breakpoint.mdAndUp ? 256 : 56},model:{value:(_vm.alertShow),callback:function ($$v) {_vm.alertShow=$$v},expression:"alertShow"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }